<template>
  <!-- 药品信息 -->
  <div class="page-section">
    <div class="container">
      <div class="content">
        <h3 class="page-title">药品信息（收费）</h3>
        <div class="page-tips">
          扫码付费5元，阅读全文！过程中如遇问题，请拨打本公司电话！
        </div>
        <a-list
          :grid="{ gutter: 16, xs: 2, sm: 3, md: 3, lg: 3, xl: 4, xxl: 4 }"
          :dataSource="productionList"
          :pagination="pagination"
          class="production-list"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <router-link :to="`${JumpToPageDetail}?id=${item.id}`">
                <production-item :info="item"></production-item>
              </router-link>
            </a-list-item>
          </template>
        </a-list>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-25 14:37:44
 *@version: V1.0.0
 *@description: 药品list页面
 *************************************************************************/
import ProductionItem from "@/components/ProductionItem.vue"; //药品信息
export default {
  name: "ProductionList",
  components: { ProductionItem },
  props: {},
  data() {
    return {
      // 产品信息
      productionList: [
        {
          id: 150,
          img: require("../assets/images/EOG001007C.png"),
          name: "利动",
          cDrugName: "乳果糖口服溶液",
        },
        {
          id: 160,
          img: require("../assets/images/EFB002030C.png"),
          name: "爱普芬",
          cDrugName: "布洛芬混悬液",
        },
        {
          id: 151,
          img: require("../assets/images/ENC002003C.png"),
          name: "易坦静",
          cDrugName: "氨溴特罗口服溶液",
        },
        {
          id: 152,
          img: require("../assets/images/BOA012001C.png"),
          name: "益动",
          cDrugName: "马来酸多潘立酮片",
        },
        {
          id: 153,
          img: require("../assets/images/HQS016011G.png"),
          name: "润口腔炎喷雾剂",
          cDrugName: "口腔炎喷雾剂",
        },

        {
          id: 155,
          img: require("../assets/images/HQB002010C.png"),
          name: "采特",
          cDrugName: "盐酸特比萘芬喷雾剂",
        },
        {
          id: 156,
          img: require("../assets/images/HGF005001C.png"),
          name: "锐迈",
          cDrugName: "酮洛芬凝胶",
        },
        {
          id: 158,
          img: require("../assets/images/DAB007021D.png"),
          name: "润头孢克洛颗粒",
          cDrugName: "头孢克洛颗粒",
        },
        {
          id: 157,
          img: require("../assets/images/ILELEYSJ20P.png"),
          name: "iLELE益生菌固体饮料",
          cDrugName: "iLELE益生菌固体饮料",
        },
        {
          id: 159,
          img: require("../assets/images/DOE001070C.png"),
          name: "美常灵",
          cDrugName: "蒙脱石散",
        },

        {
          id: 161,
          img: require("../assets/images/EFA008020C.png"),
          name: "彤朗",
          cDrugName: "对乙酰氨基酚口服溶液",
        },
        {
          id: 162,
          img: require("../assets/images/EWN020001C.png"),
          name: "宝丽亚",
          cDrugName: "吸入用丙酸倍氯米松混悬液",
        },

        {
          id: 163,
          img: require("../assets/images/EWN027001C.png"),
          name: "易安平",
          cDrugName: "吸入用盐酸氨溴索溶液",
        },
        {
          id: 164,
          img: require("../assets/images/BUC007007F.png"),
          name: "美常安",
          cDrugName: "枯草杆菌二联活菌肠溶胶囊",
        },
        {
          id: 154,
          img: require("../assets/images/ENA005155C.png"),
          name: "易坦静berry",
          cDrugName: "盐酸氨溴索口服溶液",
        },
        {
          id: 78,
          img: require("../assets/images/DUC001007F.png"),
          name: "妈咪爱",
          cDrugName: "枯草杆菌二联活菌颗粒",
        },
        {
          id: 149,
          img: require("../assets/images/kfysby.png"),
          name: "润口服补液盐散(Ⅲ)",
          cDrugName: "口服补液盐散(Ⅲ)",
        },

        {
          id: 200,
          img: require("../assets/images/tdx123.jpg"),
          name: "泰得欣",
          cDrugName: "注射用头孢他啶",
        },
      ],
      pagination: {
        pageSize: 8,
        itemRender: (current) => {
          if (current.type === "page") {
            return current.page;
          } else if (current.type === "prev") {
            return <a-button>上一页</a-button>;
          } else if (current.type === "next") {
            return <a-button type="primary">下一页</a-button>;
          } else {
            return <span>•••</span>;
          }
        },
      },
    };
  },

  computed: {
    JumpToPageDetail() {
      let rmkScssUserName = localStorage.getItem("rmkScssUserName");
      let path = "";
      if (rmkScssUserName) {
        path = "/production/detailInfo";
      } else {
        path = "/production/detail";
      }
      return path;
    },
  },
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.page-tips {
  margin-bottom: 20px;
  text-align: left;
  text-indent: 2em;
  font-size: 16px;
  line-height: 1.8;
}
.production-list {
  :deep(.ant-list-pagination) {
    text-align: center;
  }
  :deep(.ant-pagination-item) {
    width: 60px;
    margin-right: 10px;
  }
  :deep(.ant-pagination-item),
  :deep(.ant-pagination-prev),
  :deep(.ant-pagination-next) {
    height: 40px;
    line-height: 40px;
  }
  :deep(.ant-btn) {
    height: 40px;
  }
}
</style>
